.btn {
    background-color: #0094ff;
    color: #fff;
    border: none;
    outline: none;
    font-size: 1.2rem;
    border-radius: 10px;
    padding: 11px 1rem;
    /* margin: 0 0.5rem ; */
    width: 7.5rem;
  }
  