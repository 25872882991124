.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: bisque;
    width: 28rem;
    height: fit-content;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
  }
  
  .card img {
    margin-top: -20%;
    width: 100%;
    border-radius: 20px;
  }
  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  